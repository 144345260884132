<template>
  <v-card>
    <v-card-title class="text-h6 pb-10 justify-center">
      {{ $t("userEmailCollection.title") }}
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-text-field
          filled
          :label="$t('userEmailCollection.emailLabel')"
          prepend-inner-icon="mdi-email"
          v-model="collectedInformation.email"
          :rules="[
            rules.fieldRequired($t('userEmailCollection.emailRuleRequired')),
            rules.emailValid($t('userEmailCollection.emailRuleFormat'))
          ]"
          data-cy="email"
          @input="$emit('input', { name: name, email: email })"
        />
        <v-text-field
          filled
          :label="$t('userEmailCollection.nameLabel')"
          prepend-inner-icon="mdi-account"
          v-model="collectedInformation.name"
          @input="$emit('input', { name: name, email: email })"
        />
      </v-form>
      <v-btn
        block
        color="primary"
        class="my-6"
        @click="saveNameAndEmail()"
        data-cy="submit"
        >{{ $t("buttons.start") }}</v-btn
      >
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    value: Object
  },
  data() {
    return {
      collectedInformation: this.value,
      valid: false,
      email: "",
      name: "",
      rules: {
        fieldRequired(m) {
          return v => !!v || m;
        },
        emailValid(m) {
          return v => /.+@.+\..+/.test(v) || m;
        }
      }
    };
  },
  methods: {
    saveNameAndEmail() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }
      this.$emit("input", this.collectedInformation);
      this.$emit("submit");
      this.$emit("close");
    }
  }
};
</script>
