var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", { staticClass: "text-h6 pb-10 justify-center" }, [
        _vm._v(" " + _vm._s(_vm.$t("userEmailCollection.title")) + " ")
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-form",
            {
              ref: "form",
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c("v-text-field", {
                attrs: {
                  filled: "",
                  label: _vm.$t("userEmailCollection.emailLabel"),
                  "prepend-inner-icon": "mdi-email",
                  rules: [
                    _vm.rules.fieldRequired(
                      _vm.$t("userEmailCollection.emailRuleRequired")
                    ),
                    _vm.rules.emailValid(
                      _vm.$t("userEmailCollection.emailRuleFormat")
                    )
                  ],
                  "data-cy": "email"
                },
                on: {
                  input: function($event) {
                    return _vm.$emit("input", {
                      name: _vm.name,
                      email: _vm.email
                    })
                  }
                },
                model: {
                  value: _vm.collectedInformation.email,
                  callback: function($$v) {
                    _vm.$set(_vm.collectedInformation, "email", $$v)
                  },
                  expression: "collectedInformation.email"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  filled: "",
                  label: _vm.$t("userEmailCollection.nameLabel"),
                  "prepend-inner-icon": "mdi-account"
                },
                on: {
                  input: function($event) {
                    return _vm.$emit("input", {
                      name: _vm.name,
                      email: _vm.email
                    })
                  }
                },
                model: {
                  value: _vm.collectedInformation.name,
                  callback: function($$v) {
                    _vm.$set(_vm.collectedInformation, "name", $$v)
                  },
                  expression: "collectedInformation.name"
                }
              })
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "my-6",
              attrs: { block: "", color: "primary", "data-cy": "submit" },
              on: {
                click: function($event) {
                  return _vm.saveNameAndEmail()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("buttons.start")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }